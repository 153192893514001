<template>
  <div>
    <HeaderBasic>
      <h2 class="page-title">Manage City/Town</h2>
      <router-link to="/" :exact="true">Home</router-link>
      <span> / </span>
      <span class="current">Manage City/Town</span>
    </HeaderBasic>
    <City baseUrl="/City/"></City>
  </div>
</template>
<script>
export default {
  components: {
    HeaderBasic: () => import("./HeaderBasic"),
    City: () => import("./City")
  }
};
</script>
